import React from "react";
import { Formik, Field, Form } from 'formik';

import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import ApiCall from "helpers/ApiCall.js";
import { useHistory } from "react-router-dom";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const CustomInputComponent = ({
   field, // { name, value, onChange, onBlur }
   form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
   ...props
 }) => (
   <>
     <Input {...field} {...props} />
     {touched[field.name] &&
       errors[field.name] && <div className="error">{errors[field.name]}</div>}
   </>
 );

const TextArea = tw.textarea`h-24 sm:h-full resize-none`;

const CustomTextareaComponent = ({
   field, // { name, value, onChange, onBlur }
   form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
   ...props
 }) => (
    <>
    <TextArea {...field} {...props}/>
     {touched[field.name] &&
       errors[field.name] && <div className="error">{errors[field.name]}</div>}
   </>
 );
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`


export default ({history = useHistory()}) => {
  return (
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl" id="contact">
            <h2>Contacter notre équipe</h2>
            <Formik
              initialValues={{
                name: '',
                email: '',
                message: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
               setTimeout(() => {
                 let form = document.querySelector("#simplecontactus");
                 let formData: FormData = new FormData(form);
                 ApiCall.sendPost('/contact/simplecontact', formData)
                  .then((response) => {
                  }, (error) => {
                    alert("Oups ! Une erreur s'est produite.");
                      setSubmitting(false);
                  });
                  form.style.display = 'none';

                  form.parentNode.append("Merci pour votre message, nous allons vous recontacter prochainement. Une confirmation d'envoie arrivera dans votre boîte mail.");
               }, 400);
             }}
            >
              <Form id="simplecontactus">
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Votre nom</Label>
                      <Field component={CustomInputComponent} id="name-input" type="text" name="name" placeholder="Ex. John Doe" />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">Votre Email</Label>
                      <Field component={CustomInputComponent} id="email-input" type="email" name="email" placeholder="Ex. john@mail.com" />
                    </InputContainer>
                  </Column>
                  <Column>
                    <InputContainer tw="flex-1">
                      <Label htmlFor="name-input">Votre message</Label>
                      <Field component={CustomTextareaComponent} id="message-input" name="message" placeholder="Ex. Votre demande en détails"/>
                    </InputContainer>
                  </Column>
                </TwoColumn>

                <SubmitButton type="submit" value="Submit">Envoyer</SubmitButton>
              </Form>
            </Formik>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
