import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Header from "components/fccmc/Header.js";
import Footer from "components/fccmc/Footer.js";

import HomeText from "components/fccmc/HomeText.js";
import Locations from "components/fccmc/Locations.js";
import News from "components/fccmc/News.js";
import BecomeMember from "components/fccmc/BecomeMember.js";
import ContactUsForm from "components/fccmc/SimpleContactUs.js";
import Sponsors from "components/fccmc/Sponsors.js";


import Members from "components/fccmc/Members.js";



import Features from "components/features/DashedBorderSixFeatures";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";


export default () => (
  <AnimationRevealPage>
    <Header />
    <HomeText />
    <Locations />
        <div className="embedsocial-hashtag" data-ref="80dff97dde83bb7f5682fedc00973ae478751b89">
            <a className="feed-powered-by-es feed-powered-by-es-slider-img" href="https://embedsocial.com/social-media-aggregator/" target="_blank" title="Instagram widget">
                <img src="https://embedsocial.com/cdn/images/embedsocial-icon.png" alt="EmbedSocial" /> Instagram widget </a>
        </div>
        {/*<News />
            <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Vous avez des <span tw="text-primary-500">questions ?</span>
        </>
      }
    />*/}
    <BecomeMember />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
