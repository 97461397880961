import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Header from "components/fccmc/Header.js";
import Footer from "components/fccmc/Footer.js";
import History from "components/fccmc/History.js";
import Objectives from "components/fccmc/Objectives.js";

export default () => (
  <AnimationRevealPage>
    <Header />
    <History />
    <Objectives />
    <Footer />
  </AnimationRevealPage>
);
