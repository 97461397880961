import axios from 'axios';
import * as config from '../config/config'

function sendPost(url: string, data: string | FormData, headers: Object = {}): Promise<EmailJSResponseStatus> {
  return new Promise((resolve, reject) => {
	  let xhr: XMLHttpRequest = new XMLHttpRequest();
	  xhr.open('POST', config.API_URL +url, true);
	  for (let key in headers) {
	      xhr.setRequestHeader(key, headers[key]);
	  }
	  xhr.send(data);
  });
}

export default {
  sendPost
};