import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.jpg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";

import Sponsors from "components/fccmc/SponsorsAjax.js";


const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <>
    <Sponsors />
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>FC Courtepin-Misery-Courtion.</LogoText>
          </LogoContainer>
          <CompanyDescription>
            Club de football de suisse romande<br /> lié à l'association Fribourgeoise de Football
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com/courtepinmiserycourtion">
              <FacebookIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Accès rapide</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#about">A propos</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#news">Actualités</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/join">Devenir membre</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="https://intranet.fc-cmc.ch" target="_blank">Intranet</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Locations</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">Buvette de Courtepin</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Buvette de Courtion</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Coordonnées</ColumnHeading>
          <CompanyDescription>
            FC Courtepin-Misery-Courtion<br />
            1784 Courtepin<br /><br />
            contact@fc-cmc.ch<br /><br />
            CH85 8080 8009 4200 6811 7
          </CompanyDescription>
        </Column>
      </FiveColumns>
    </Container>
    </>
  );
};
