import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import LandingPage from "pages/LandingPage.js";
import JoinPage from "pages/JoinPage.js";
import AboutPage from "pages/AboutPage.js";
import EventPage from "pages/EventPage.js";
import MembersPage from "pages/MembersPage.js";

import ComponentRenderer from "ComponentRenderer.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <Router>
      <Switch>
        <Route path="/components/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/join">
          <JoinPage />
        </Route>
        <Route path="/events">
          <EventPage />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route path="/members">
          <MembersPage />
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </Router>
  );
}

