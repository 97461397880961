import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

import axios from 'axios';
import * as config from '../../config/config'



const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;
const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/3 md:w-1/4 lg:w-1/5 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;


export default class SponsorsAjax extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
          sponsorsdata: null, 
          activeTab: null,
        }
    }

    componentDidMount () {
        this.getSponsorsData()
    }

    setActiveTab(tabName) {
        this.setState( {activeTab: tabName});
    }
    getSponsorsData () {
      axios.get(config.API_URL +"/sponsors/web").then(result => {          
        if (result.status === 200) 
        {
          const principaux =[];
          result.data.forEach(function(element)
          {
            principaux[element.id] = element;
            principaux[element.id]["imageabs"] = config.URL+"/"+element.image;
          });

          const tabs = {
            Principaux: principaux
          };
          this.setState({sponsorsdata: tabs});
          this.setActiveTab(Object.keys(tabs)[0]);
        }
      });
    }
    render() {
      if (!this.state.sponsorsdata) 
        return <></>;
      const tabsKeys = Object.keys(this.state.sponsorsdata);
        return (
          <div id="members">
            <Container>
              <ContentWithPaddingXl>
                <HeaderRow>
                  <Header>Nos sponsors</Header>
                </HeaderRow>

                {tabsKeys.map((tabKey, index) => (
                  <TabContent
                    key={index}
                    variants={{
                      current: {
                        opacity: 1,
                        scale:1,
                        display: "flex",
                      },
                      hidden: {
                        opacity: 0,
                        scale:0.8,
                        display: "none",
                      }
                    }}
                    transition={{ duration: 0.4 }}
                    initial={this.state.activeTab === tabKey ? "current" : "hidden"}
                    animate={this.state.activeTab === tabKey ? "current" : "hidden"}
                  >
                    {this.state.sponsorsdata[tabKey].map((card, index) => (
                      <CardContainer key={index}>
                        <Card className="group" href={card.url} target="blank" initial="rest" whileHover="hover" animate="rest">
                          <img class="w-32 h-32 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto" src={card.imageabs}/>
                        </Card>
                      </CardContainer>
                    ))}
                  </TabContent>
                ))}
              </ContentWithPaddingXl>
            </Container>
          </div>
      );
    }
}


