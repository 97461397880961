import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

import FCCMCLogo from "images/logo.jpg";
import TerrainMaraisImage from "images/terrain_courtepin_2018.jpg";
import FestisportImage from "images/festisport.jpg";
import Tournoi30Image from "images/tournoi30_2024.jpg";


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: TerrainMaraisImage,
      title: "Diner d'entreprise",
      description: "Promotion mouvement juniors",
      locationText: "Café de la presse",
      timeText: "24 Janvier 2025",
    },
    {
      imageSrc: TerrainMaraisImage,
      title: "Tournoi club",
      description: "Informations à venir",
      locationText: "-",
      timeText: "13 Juin 2025",
    }/*,{
      imageSrc: TerrainMaraisImage,
      title: "Prochainement...",
      description: "",
      locationText: "",
      timeText: "",
    },
    {
      imageSrc: "https://sekulic2020.ch/wp-content/uploads/2020/02/GettyImages-486400996-kids-playing-soccer-1120-1.png",
      title: "Assemblée générale",
      description: "",
      locationText: "",
      timeText: "27.08.2021 - 19h30",
      url : "https://fc-cmc.ch/"
    }*/
  ]


  function ShowMoreInfo(props) {
    if(props.card.url)
      return <PrimaryButton as="a" href={props.card.url} target="_blank">Plus d'infos</PrimaryButton>;
    return <></>;
}

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Manifestations</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer>
                      <TimeIcon />
                    </IconContainer>
                    <Text>{card.timeText}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <ShowMoreInfo card={card}/>
            </Card>
          ))}
        </CardSlider>
        <HeadingWithControl>
          <Heading>Tournois E / F</Heading>
        </HeadingWithControl>
        <p><a class="uk-button" href="http://www.aff-ffv.ch/desktopdefault.aspx/tabid-3570/10512_read-199656/" target="_blank" rel="noopener noreferrer">Calendrier des matchs - Juniors E</a></p>
        <p><a class="uk-button" href="http://www.aff-ffv.ch/desktopdefault.aspx/tabid-3570/10512_read-207377/" target="_blank" rel="noopener noreferrer">Calendrier des matchs - Juniors F</a></p>
      </Content>
    </Container>
  );
};
