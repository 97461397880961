import React from "react";
import { Formik, Field, Form } from 'formik';

import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import { motion } from "framer-motion";
import ApiCall from "helpers/ApiCall.js";
import { useHistory } from "react-router-dom";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-2 md:py-4`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const Label = tw.p`mt-6 first:mt-0 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Content = tw.div`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`

const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const CustomInputComponent = ({
   field, // { name, value, onChange, onBlur }
   form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
   ...props
 }) => (
   <>
     <Input {...field} {...props} />
     {touched[field.name] &&
       errors[field.name] && <div className="error">{errors[field.name]}</div>}
   </>
 );


const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const CustomTextareaComponent = ({
   field, // { name, value, onChange, onBlur }
   form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
   ...props
 }) => (
    <>
    <Textarea {...field} {...props}/>
     {touched[field.name] &&
       errors[field.name] && <div className="error">{errors[field.name]}</div>}
   </>
 );
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`


const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);



export default ({
  subheading = "Rejoins-nous.",
  heading = <>Envi d'être  <span tw="text-primary-500">membre d'une équipe</span><wbr/>? </>,
  description = "Améliorer la qualité, évoluer au niveau supérieur reste des challenges intéressants, motivants et passionnants. Depuis sa création, le comité met tout en œuvre afin que chaque junior ait un maximum de plaisir.",
  submitButtonText = "Inscription",
  history = useHistory()
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <TextColumn>
          <Content>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
          </Content>
        </TextColumn>
        <TextColumn>
          <Content>
            {description && <Description>{description}</Description>}
          </Content>
        </TextColumn>
      </TwoColumn>
      <Formik
        initialValues={{
          lastname: '',
          firstname :'',
          email: '',
          phone: '',
          adresse: '',
          birthday: '',
          legal_lastname: '',
          legal_firstname :'',
          legal_email: '',
          legal_phone: '',
          legal_adresse: '',
          lastclub: '',
          comment: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
         setTimeout(() => {
            let form = document.querySelector("#becomememberform");
            let formData: FormData = new FormData(form);
            ApiCall.sendPost('/contact/registercontact', formData)
            .then((response) => {
              
            }, (error) => {
              alert("Oups ! Une erreur s'est produite.");
              setSubmitting(false);
            });
            form.style.display = 'none';
            form.parentNode.append("Merci pour votre message, nous allons vous recontacter prochainement. Une confirmation d'envoie arrivera dans votre boîte mail.");
               
           }, 400);
         }}
      >
      <Form id="becomememberform">
        <TwoColumn>
          <TextColumn>
            <Content>
              <Description>Joueur</Description>
              <Field component={CustomInputComponent} type="text" id="lastname" name="lastname"  placeholder="Nom" />
              <Field component={CustomInputComponent} type="text" name="firstname" placeholder="Prénom" />
              <Field component={CustomInputComponent} type="email" name="email" placeholder="Email" />
              <Field component={CustomInputComponent} type="text" name="phone" placeholder="Téléphone" />
              <Field component={CustomTextareaComponent} name="adresse" placeholder="Adresse postale" />
              <Label>Date de naissance</Label>
              <Field component={CustomInputComponent} type="date" name="birthday" />
            </Content>
          </TextColumn>
          <TextColumn>
            <Content>
              <Description>Représentant légal</Description>
              <Field component={CustomInputComponent} type="text" name="legal_lastname" placeholder="Nom" />
              <Field component={CustomInputComponent} type="text" name="legal_firstname" placeholder="Prénom" />
              <Field component={CustomInputComponent} type="email" name="legal_email" placeholder="Email" />
              <Field component={CustomInputComponent} type="text" name="legal_phone" placeholder="Téléphone" />
              <Field component={CustomTextareaComponent} name="legal_adresse" placeholder="Adresse postale" />
              <br />
              <Description>Compléments</Description>
              <Field component={CustomInputComponent} type="text" name="lastclub" placeholder="Club précédent" />
              <Field component={CustomTextareaComponent} name="comment" placeholder="Commentaires" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Content>
          </TextColumn>
        </TwoColumn>
      </Form>
    </Formik>
    </Container>
  );
};
